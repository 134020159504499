/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { NavLink, useRouteMatch } from "react-router-dom";

const El = styled(NavLink)({
  "&.active": {
    borderBottom: "2px solid #AB47BC",
    color: "#000",
  },
  ":hover": {
    borderBottom: "2px solid #E5E5E5",
    color: "#000",
  },
  borderColor: "transparent",
  color: "#5E5E5E",
  marginRight: "1rem",
  padding: "0.4rem",
  textDecoration: "none",
  textSize: "0.8rem",
  transition: "color 120ms ease-in 0s,border-color 120ms ease-in 0s",
  whiteSpace: "nowrap",
});

const DashboardHeader = ({ className }) => {
  const { url } = useRouteMatch();
  return (
    <div
      css={{
        display: "flex",
        overflow: "auto",
        padding: "0 1rem",
        scrollbarWidth: "none",
      }}
      className={className}
    >
      <El to={`${url}`} exact activeClassName="active">
        Vue d'ensemble
      </El>
      {/* <El to={`${url}/integrations`} activeClassName="active">
        Skills
      </El> */}
      <El to={`${url}/usage`} activeClassName="active">
        Consommation
      </El>
      <El to={`${url}/subscription`} activeClassName="active">
        Abonnement
      </El>
    </div>
  );
};

export default DashboardHeader;
