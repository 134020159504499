/** @jsx jsx */
import { jsx } from "@emotion/core";
import { DateTime } from "luxon";
import Skeleton from "react-loading-skeleton";
import { Link, useRouteMatch } from "react-router-dom";

import { buttonStyle, cardBorder } from "../styles";

const strings = {
  service: {
    aircall: "Aircall",
    twilio: "Renvoi d'appel",
    undefined: "Résumé",
  },
};

const UsageCard = ({ usage, className }) => {
  const { url } = useRouteMatch();
  const [date, endDate] = [
    DateTime.fromISO(usage?.date),
    DateTime.fromISO(usage?.endDate),
  ];
  return (
    <div
      css={{
        ...cardBorder,
        alignItems: "center",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "1rem",
      }}
      className={className}
    >
      <div
        css={{
          alignSelf: "flex-start",
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        {strings.service[usage?.service]}
      </div>
      <div css={{ color: "#AB47BC", marginBottom: "1rem" }}>
        {endDate.isValid
          ? `${date.toLocaleString()} — ${endDate.toLocaleString()}`
          : "ce mois-ci"}
      </div>
      <div
        css={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        {usage?.transcripts ?? usage?.messages ?? (
          <Skeleton
            css={{ display: "inline-block" }}
            width="3rem"
            duration={0.8}
          />
        )}
      </div>
      <div css={{ marginBottom: "1rem" }}>retranscriptions</div>
      {url !== "/dashboard/usage" && (
        <Link
          to="/dashboard/usage"
          css={{
            ...buttonStyle,
            alignSelf: "flex-end",
          }}
        >
          Consommation
        </Link>
      )}
    </div>
  );
};

export default UsageCard;
