/** @jsx jsx */

import { jsx } from "@emotion/core";

import API from "../API";
import PageTitle from "../components/PageTitle";
import UsageCard from "../components/UsageCard";
import { mq } from "../styles";

const Usage = () => {
  const { data } = API.get("/usage");

  return (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        margin: "1rem",
        [mq[0]]: {
          margin: "1rem 10vw",
        },
      }}
    >
      <PageTitle
        title="Consommation"
        caption="Un aperçu de votre utilisation de Gaston sur cette période."
      />
      <div
        css={{
          maxWidth: "360px",
        }}
      >
        <UsageCard css={{ margin: "0 0 1.4rem" }} usage={data?.usage} />
        {data?.usage.usages?.map((usage) => (
          <UsageCard
            css={{ margin: "0 0 1.4rem" }}
            key={usage.service}
            usage={usage}
          />
        ))}
      </div>
    </div>
  );
};

export default Usage;
