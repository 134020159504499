/** @jsx jsx */

import Bugsnag from "@bugsnag/js";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Color from "color";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { mutate } from "swr";

import API from "../API";
import {
  Button,
  Checkbox,
  Radio,
  TextInput as _TextInput,
} from "../components/Form";
import _PlanCard, { PlanFeature } from "../components/PlanCard";
import { mq, fonts } from "../styles";

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TextInput = styled(_TextInput)({ marginBottom: "1rem" });

const H1 = styled.h1({
  ...fonts.serif,
  fontSize: "1.8rem",
  margin: "0 0 2rem",
});

const Identity = ({
  values: { firstName, lastName, email, acceptsPromotionalCommunications },
  setValue,
  next,
  setError,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(
      firstName.length > 0 && lastName.length > 0 && email.match(emailRegex)
    );
  }, [firstName, lastName, email]);

  const submit = async () => {
    if (isValid) {
      setIsLoading(true);
      try {
        await API.do.post("/user", {
          email,
          firstName,
          lastName,
          parameters: {
            acceptsPromotionalCommunications,
          },
        });
        next();
      } catch (error) {
        setError(error);
      }
    }
  };

  return (
    <>
      <H1>Parlez-nous de vous</H1>
      <div>
        <TextInput
          id="firstName"
          name="firstName"
          onChange={(e) => setValue("firstName", e.target.value)}
          placeholder="Alex"
          title="Prénom"
          type="text"
          value={firstName}
        />
        <TextInput
          id="lastName"
          name="lastName"
          onChange={(e) => setValue("lastName", e.target.value)}
          placeholder="Dupont"
          title="Nom"
          type="text"
          value={lastName}
        />
        <TextInput
          id="email"
          onChange={(e) => setValue("email", e.target.value)}
          name="email"
          placeholder="exemple@domaine.fr"
          title="Adresse e-mail"
          type="email"
          value={email}
        />
        <Checkbox
          onChange={(e) =>
            setValue("acceptsPromotionalCommunications", e.target.checked)
          }
          checked={acceptsPromotionalCommunications}
          type="checkbox"
          title={
            <small>
              J'accepte de recevoir un petit e-mail de temps en temps (nouvelles
              fonctionnalités, offres…)
            </small>
          }
        />
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          margin: "2rem 0 0",
        }}
      >
        <small
          css={{
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          {"En continuant, vous acceptez la "}
          <a
            href="https://gaston.tel/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Charte de confidentialité
          </a>
        </small>
        <Button
          disabled={isLoading || !isValid}
          loading={isLoading}
          onClick={submit}
          type="submit"
        >
          Continuer
        </Button>
      </div>
    </>
  );
};

const BusinessInfo = ({
  next,
  setError,
  setValue,
  values: { jobTitle, company },
}) => {
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsValid(jobTitle.length > 0 && company.length > 0);
  }, [jobTitle, company]);

  const submit = async () => {
    if (isValid) {
      setIsLoading(true);
      try {
        await API.do.post("/user", {
          company,
          jobTitle,
        });
        next();
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <H1>Dites-nous en plus sur votre profession</H1>
      <div>
        <TextInput
          id="jobTitle"
          name="jobTitle"
          onChange={(e) => setValue("jobTitle", e.target.value)}
          placeholder="Directeur Commercial"
          title="Fonction"
          type="text"
          value={jobTitle}
        />
        <TextInput
          id="company"
          name="company"
          onChange={(e) => setValue("company", e.target.value)}
          placeholder="ACME"
          title="Entreprise"
          type="text"
          value={company}
        />
      </div>
      <Button
        disabled={isLoading || !isValid}
        loading={isLoading}
        onClick={submit}
        type="submit"
      >
        Continuer
      </Button>
    </>
  );
};

const UseType = ({ next, setValue, values }) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(values.type !== null);
  }, [values.type]);

  return (
    <>
      <H1>Comment allez-vous utiliser Gaston ?</H1>
      <div
        css={{ marginBottom: "2rem" }}
        onChange={(e) => setValue("type", e.target.value)}
      >
        <Radio
          id="personal"
          name="type"
          title="À titre personnel"
          value="personal"
        />
        <Radio
          id="professional"
          name="type"
          title="À titre professionnel"
          value="professional"
        />
      </div>
      <Button disabled={!isValid} onClick={next} type="submit">
        Continuer
      </Button>
    </>
  );
};

const PlanCard = styled(_PlanCard)({
  margin: "0 0 2rem",
});

const PersonalPlan = () => (
  <PlanCard
    name="Formule Personnelle"
    description="Pour un usage personnel et à faible volume."
    price={["3,99 €", "TTC", "par mois, sans engagement"]}
    topFeatures={[
      <PlanFeature
        css={{ marginBottom: "0.4rem" }}
        icon="voicemail"
        key="transcripts"
      >
        40 retranscriptions / mois
      </PlanFeature>,
      <PlanFeature icon="stopwatch" key="message-duration">
        60 secondes / message
      </PlanFeature>,
    ]}
  >
    <PlanFeature>Annonce personnalisée</PlanFeature>
    <PlanFeature>Stockage illimité</PlanFeature>
    <PlanFeature>Notifications par e-mail</PlanFeature>
  </PlanCard>
);

const ProfessionalPlan = () => (
  <PlanCard
    name="Formule Professionnelle"
    description="Travaillez plus efficacement grâce à Gaston."
    price={["15 €", "HT", "par utilisateur par mois"]}
    topFeatures={[
      <PlanFeature
        css={{ fontWeight: "600", marginBottom: "4px" }}
        icon="voicemail"
        key="transcripts"
      >
        Retranscriptions illimitées sur mobiles
      </PlanFeature>,
      <PlanFeature icon="stopwatch" key="message-duration">
        120 secondes / message
      </PlanFeature>,
    ]}
  >
    <PlanFeature>Annonce personnalisée</PlanFeature>
    <PlanFeature>Stockage illimité</PlanFeature>
    <PlanFeature>Notifications par e-mail</PlanFeature>
    <PlanFeature css={{ fontWeight: "600" }}>
      Raccordement sur ligne fixe/standard
    </PlanFeature>
    <PlanFeature css={{ fontWeight: "600" }}>Facturation de groupe</PlanFeature>
    <PlanFeature css={{ fontWeight: "600" }}>
      Assistance prioritaire
    </PlanFeature>
    <PlanFeature css={{ fontWeight: "600", marginBottom: 0 }}>
      Intégrations
    </PlanFeature>
  </PlanCard>
);

const Trial = ({ next, type }) => {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    setIsLoading(true);
    try {
      await API.do.post("/subscriptions/trial", {
        planName: type,
      });
      await mutate("/user");
      next();
    } catch (err) {
      // TODO: Handle errors
      Bugsnag.notify(err);
    } finally {
      setIsLoading(false);
    }
  };

  const Plan = type === "professional" ? ProfessionalPlan : PersonalPlan;

  return (
    <>
      <div css={{ marginBottom: "2rem" }}>
        <H1>Essayez Gaston gratuitement pendant 15 jours</H1>
        <div>
          Pas besoin de carte de crédit, vous validerez votre formule à la fin
          de votre essai.
        </div>
      </div>
      <Plan />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <small
          css={{
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          {"En continuant, vous acceptez les "}
          <a
            href="https://gaston.tel/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Conditions d'utilisation
          </a>
        </small>
        <Button
          css={{ fontWeight: "bold" }}
          disabled={isLoading}
          loading={isLoading}
          onClick={submit}
          type="submit"
        >
          Commencer mon essai
        </Button>
      </div>
    </>
  );
};

const Onboarding = () => {
  const { data: userData = {} } = API.get("/user");
  const { user } = userData;

  const history = useHistory();

  const [step, setStep] = useState("identity");
  const [error, setError] = useState();
  const [values, _setValues] = useState({
    acceptsPromotionalCommunications:
      user?.parameters?.acceptsPromotionalCommunications || false,
    company: user?.company || "",
    email: user?.email || "",
    firstName: user?.firstName || "",
    jobTitle: user?.jobTitle || "",
    lastName: user?.lastName || "",
    type: null,
  });

  useEffect(() => {
    _setValues((v) => ({
      ...v,
      acceptsPromotionalCommunications:
        user?.parameters?.acceptsPromotionalCommunications || false,
      company: user?.company || "",
      email: user?.email || "",
      firstName: user?.firstName || "",
      jobTitle: user?.jobTitle || "",
      lastName: user?.lastName || "",
    }));
  }, [user]);

  const next = () => {
    switch (step) {
      case "identity":
        setStep("useType");
        break;
      case "useType":
        if (values.type === "professional") setStep("businessInfo");
        else setStep("trial");
        break;
      case "businessInfo":
        setStep("trial");
        break;
      case "trial":
        history.push("/dashboard");
        break;
      default:
        break;
    }
  };

  const setValue = (id, value) => _setValues({ ...values, [id]: value });

  return (
    <div
      css={{
        alignItems: "center",
        borderTop: "1px solid #E5E5E5",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 1rem",
        padding: "2rem 0",
        [mq[0]]: {
          margin: "0 10vw",
        },
      }}
    >
      <form
        css={{
          [mq[0]]: {
            border: `1px solid ${Color("white").darken(0.1)}`,
            borderRadius: "0.4rem",
            boxShadow: `0 0 0.4rem 0px ${Color("black").fade(0.9)}`,
            maxWidth: "320px",
            padding: "2rem",
          },
          alignItems: "stretch",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "420px",
          width: "100%",
        }}
        onSubmit={(event) => event.preventDefault()}
      >
        {error && (
          <div
            css={{
              backgroundColor: "red",
              borderRadius: "0.2rem",
              color: "#FFF",
              margin: "0 0 1rem",
              padding: "0.4rem 0.8rem",
            }}
          >
            Une erreur s'est produite, merci de réessayer
          </div>
        )}
        {step === "identity" && (
          <Identity
            next={next}
            setError={setError}
            setValue={setValue}
            values={values}
          />
        )}
        {step === "useType" && (
          <UseType next={next} setValue={setValue} values={values} />
        )}
        {step === "businessInfo" && (
          <BusinessInfo next={next} setValue={setValue} values={values} />
        )}
        {step === "trial" && <Trial next={next} type={values.type} />}
      </form>
    </div>
  );
};

export default Onboarding;
