/** @jsx jsx */

import { jsx } from "@emotion/core";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import useAuth from "../context/auth";
import { mq, buttonStyle, fonts } from "../styles";

const Header = () => {
  const { setAuthenticated, isAuthenticated } = useAuth();

  return (
    <div
      css={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        margin: "0 1rem",
        padding: "1rem 1.4rem",
        [mq[0]]: {
          margin: "0 10vw",
        },
      }}
    >
      <NavLink
        to="/"
        css={{
          ...fonts.serif,
          color: "#AB47BC",
          fontSize: "1.6rem",
          fontWeight: "bold",
          textDecoration: "none",
        }}
      >
        Gaston
      </NavLink>
      <div>
        {isAuthenticated && (
          <button
            type="button"
            css={{
              ...buttonStyle,
              color: "red",
            }}
            onClick={() => {
              localStorage.clear();
              setAuthenticated(false);
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span
              css={{
                display: "none",
                margin: "0 0 0 .2rem",
                [mq[0]]: {
                  display: "inherit",
                },
              }}
            >
              Déconnexion
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
