/** @jsx jsx */

import Bugsnag from "@bugsnag/js";
import { jsx } from "@emotion/core";
import { CancelToken, isCancel as isCancelError } from "axios";
import { AsYouType, parsePhoneNumberFromString } from "libphonenumber-js";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { Rifm } from "rifm";

import API from "../API";
import { TextInput, Button } from "../components/Form";
import useAuth from "../context/auth";
import { mq, fonts, cardBorder } from "../styles";

const parseCode = (str) => (str.match(/\d+/g) || []).join("").substr(0, 6);

const parsePhone = (str) => (str.match(/\+?\d*/g) || []).join("").substr(0, 12);

const formatPhone = (string) => {
  const phone = new AsYouType("FR");
  const newString = phone.input(parsePhone(string));
  return newString;
};

const formatCode = (string) => {
  const code = parseCode(string)
    .split("")
    .reduce((r, v, index) => (index === 3 ? `${r} - ${v}` : `${r}${v}`), "");

  return string.endsWith("- ") && code.length === 3 ? `${code} - ` : code;
};

let cancelRequest = () => {};

const Login = ({ location }) => {
  // Page
  const referer = location.state?.referer || "/";
  const context = location.pathname;
  const { isAuthenticated, setAuthenticated } = useAuth();

  // Inputs
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");

  // Form
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [step, setStep] = useState("phone");

  useEffect(() => {
    if (step === "phone") {
      setIsValid(parsePhoneNumberFromString(phone, "FR")?.isPossible());
    } else if (step === "code") {
      setIsValid(parseCode(code).length === 6);
    }
  }, [phone, code, step]);

  const resetForm = () => {
    cancelRequest();
    setError("");
    setIsLoading(false);
    setIsValid(false);
    setStep("phone");
    setCode("");
  };

  const next = async () => {
    const formattedPhone = parsePhoneNumberFromString(phone, "FR").format(
      "E.164"
    );
    const formattedCode = parseCode(code);

    setIsLoading(true);
    setError("");

    if (step === "phone") {
      try {
        await API.do.post("/oauth/code", {
          client_id: API.clientId,
          client_secret: API.clientSecret,
          phone: formattedPhone,
        });

        setIsValid(false);
        setStep("code");
      } catch (err) {
        setError(
          <>
            {
              "Erreur lors de la connexion, merci de réessayer. Si le problème persiste, "
            }
            <a href="mailto:support@gaston.tel">contactez-nous</a>
          </>
        );
        throw err;
      } finally {
        setIsLoading(false);
      }
    } else if (step === "code") {
      try {
        await API.authenticate(
          { code: formattedCode, phone: formattedPhone },
          {
            cancelToken: new CancelToken((c) => {
              cancelRequest = c;
            }),
          }
        );

        setAuthenticated(true);
      } catch (err) {
        if (err.response?.data?.message === "Invalid code.") {
          setError("Code invalide");
        } else if (!isCancelError(error)) {
          setError(
            <>
              {
                "Erreur lors de la connexion, merci de réessayer. Si le problème persiste, "
              }
              <a href="mailto:support@gaston.tel">contactez-nous</a>
            </>
          );
          setIsLoading(false);
          Bugsnag.notify(err);
        }
      }
    }
  };

  if (isAuthenticated) return <Redirect to={referer} />;

  return (
    <div
      css={{
        alignItems: "center",
        borderTop: "1px solid #E5E5E5",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 1rem",
        padding: "2rem 0",
        [mq[0]]: {
          margin: "0 10vw",
        },
      }}
    >
      <form
        onSubmit={(event) => event.preventDefault()}
        css={{
          [mq[0]]: {
            ...cardBorder,
            maxWidth: "320px",
            padding: "2rem",
          },
          alignItems: "stretch",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "420px",
          width: "100%",
        }}
      >
        <h1
          css={{
            ...fonts.serif,
            margin: "0 0 2rem",
          }}
        >
          {context === "/login" ? "Connexion" : "Inscription"}
        </h1>
        <div css={{ margin: "0 0 2rem" }}>
          <Rifm
            value={phone}
            onChange={setPhone}
            format={formatPhone}
            accept={/\+?\d*/g}
            mask
          >
            {({ value, onChange }) => (
              <TextInput
                className={step === "phone" && error && "error"}
                disabled={isLoading || step === "code"}
                error={error}
                helperText={
                  step === "phone" ? (
                    "Vous allez recevoir un code de validation"
                  ) : (
                    <>
                      {"Mauvais numéro ? "}
                      <button
                        onClick={resetForm}
                        type="button"
                        css={{
                          appearance: "none",
                          background: "none",
                          border: "none",
                          color: "#AB47BC",
                          cursor: "pointer",
                          margin: "0",
                          outline: "none",
                          padding: "0",
                          textDecoration: "underline",
                        }}
                      >
                        Changer
                      </button>
                    </>
                  )
                }
                id="phone"
                inputMode="tel"
                onChange={onChange}
                placeholder="+33 6 12 34 56 78"
                title="Numéro de téléphone"
                type="tel"
                value={value}
              />
            )}
          </Rifm>
          {step === "code" && (
            <Rifm
              accept={/\d+/g}
              append={(str) => (str.length === 3 ? `${str} - ` : str)}
              format={formatCode}
              mask
              onChange={setCode}
              value={code}
            >
              {({ value, onChange }) => (
                <TextInput
                  css={{ margin: "1rem 0 0" }}
                  autoComplete="one-time-code"
                  autoFocus
                  error={error}
                  helperText="Peut être reçu par e-mail si ce n'est pas votre première connexion"
                  id="code"
                  inputMode="numeric"
                  name="code"
                  onChange={onChange}
                  placeholder="555 - 555"
                  title="Code de validation"
                  type="text"
                  value={value}
                />
              )}
            </Rifm>
          )}
        </div>
        <Button
          loading={isLoading}
          disabled={isLoading || !isValid}
          onClick={next}
          type="submit"
        >
          Continuer
        </Button>
      </form>
      {context === "/login" && step === "phone" && (
        <NavLink
          to={{
            pathname: "/signup",
            state: {
              referer,
            },
          }}
          css={{
            ":hover": {
              textDecoration: "underline",
            },
            color: "#AB47BC",
            margin: "1rem 0 0",
            padding: "0.4rem 0.8rem",
            textDecoration: "none",
          }}
        >
          Pas de compte ? Inscrivez-vous →
        </NavLink>
      )}
    </div>
  );
};

export default Login;
