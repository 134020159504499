/** @jsx jsx */

import { jsx } from "@emotion/core";
import Color from "color";
import { DateTime } from "luxon";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";

import { buttonStyle, cardBorder } from "../styles";

import SubscriptionModal from "./SubscriptionModal";

const TrialCard = ({ className, endTrialDate }) => {
  const isSoon =
    DateTime.local() >= DateTime.fromISO(endTrialDate).minus({ days: 3 });
  const strings = isSoon
    ? {
        caption:
          "Merci d'avoir testé Gaston. Abonnez-vous dès maintenant pour continuer à utiliser le service.",
        title: "Votre essai se termine bientôt.",
      }
    : {
        caption: `Votre période d'essai se termine ${DateTime.fromISO(
          endTrialDate
        ).toRelativeCalendar()}.`,
        title: "Vous êtes en essai gratuit.",
      };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        css={{
          ...cardBorder,
          backgroundColor: "#FFF",
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
        className={className}
      >
        <div
          css={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            marginBottom: "0.4rem",
          }}
        >
          {strings.title}
        </div>
        <div css={{ marginBottom: "1rem" }}>{strings.caption}</div>
        <button
          type="button"
          css={{
            ...buttonStyle,
            alignSelf: "flex-end",
            ...(isSoon && {
              ":hover": {
                backgroundColor: Color("#AB47BC").darken(0.1).string(),
              },
              backgroundColor: "#AB47BC",
              color: "#FFF",
            }),
          }}
          onClick={() => setIsModalOpen(true)}
        >
          Je m'abonne
        </button>
      </div>
      {isModalOpen && (
        <SubscriptionModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      )}
    </>
  );
};

export default TrialCard;
