/** @jsx jsx */

import { jsx } from "@emotion/core";
import Color from "color";
import { Link } from "react-router-dom";

import { buttonStyle, cardBorder } from "../styles";

const ExpiredCard = ({ className }) => (
  <div
    css={{
      ...cardBorder,
      backgroundColor: "#FFF",
      display: "flex",
      flexDirection: "column",
      padding: "1rem",
    }}
    className={className}
  >
    <div
      css={{
        fontSize: "1.2rem",
        fontWeight: "bold",
        marginBottom: "0.4rem",
      }}
    >
      Vous n'êtes plus abonné.
    </div>
    <div css={{ marginBottom: "1rem" }}>
      Votre abonnement a expiré et vos correspondants ne peuvent plus vous
      laisser de messages. Mettez à jour votre moyen de paiement ou
      réabonnez-vous.
    </div>
    <Link
      to="/dashboard/subscription"
      css={{
        ...buttonStyle,
        ":hover": {
          backgroundColor: Color("#f44336").darken(0.1).string(),
        },
        alignSelf: "flex-end",
        backgroundColor: "#f44336",
        color: "#FFF",
      }}
    >
      Gérer mon abonnement
    </Link>
  </div>
);

export default ExpiredCard;
