/** @jsx jsx */

import { jsx, ClassNames } from "@emotion/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loadStripe } from "@stripe/stripe-js";
import Color from "color";
// Forced to import React because of a bug with React Fragment shorthand involving emotion/babel
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import API from "../API";
import { formatPrice } from "../lib";
import { buttonStyle, fonts } from "../styles";

import { Loading } from "./Form";

const Radio = ({ selected }) => (
  <div
    css={{
      alignItems: "center",
      borderRadius: "50%",
      boxShadow: "0 0 0 2px #E5E5E5",
      display: "flex",
      flexShrink: "0",
      height: "2rem",
      justifyContent: "center",
      marginRight: "1rem",
      transition: "background 120ms ease-in 0s",
      width: "2rem",
      ...(selected && {
        backgroundColor: "#AB47BC",
      }),
    }}
  >
    {selected && (
      <FontAwesomeIcon
        icon="check"
        css={{
          color: "#FFF",
          fontSize: "1rem",
        }}
      />
    )}
  </div>
);

const PlanChoiceCard = ({
  title,
  description,
  className,
  selected,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={className}
    css={{
      alignItems: "center",
      backgroundColor: "#F3F3F3",
      border: "none",
      borderRadius: "0.4rem",
      display: "flex",
      padding: "1rem 1rem",
    }}
  >
    <Radio selected={selected} />
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          fontSize: "1.4rem",
          fontWeight: "bold",
          marginBottom: "0.2rem",
          textAlign: "center",
        }}
      >
        {title}
      </div>
      <div
        css={{
          fontSize: "1rem",
          textAlign: "start",
        }}
      >
        {description}
      </div>
    </div>
  </button>
);
const PriceChoiceCard = ({
  promoted,
  header,
  title,
  advantage,
  className,
  selected,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={className}
    css={{
      alignItems: "center",
      backgroundColor: "#F3F3F3",
      border: "none",
      borderRadius: "0.4rem",
      display: "flex",
      padding: "1rem 1rem",
      ...(promoted && {
        backgroundColor: "#F5F1F6",
        boxShadow: `-1rem -1rem 2rem #f3f3f3,
          1rem 1rem 2rem rgba(119, 55, 170, 0.1),
          inset .4rem .4rem 1rem rgba(236, 236, 236, 0.5)`,
      }),
    }}
  >
    <Radio selected={selected} />
    <div
      css={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          fontSize: "1rem",
          fontWeight: "bold",
          marginBottom: "0.2rem",
        }}
      >
        {header}
      </div>
      <div
        css={{
          fontSize: "1.4rem",
          fontWeight: "bold",
        }}
      >
        {title}
      </div>
      {advantage && (
        <div
          css={{
            backgroundColor: "#8BC34A",
            borderRadius: "0.2rem",
            color: "#FFF",
            fontSize: "1rem",
            fontWeight: "bold",
            margin: "0.4rem 0 0",
            padding: "0.4rem 0.8rem",
          }}
        >
          {advantage}
        </div>
      )}
    </div>
  </button>
);

const PlanSelection = ({ next, plans, selectedPlan, setSelectedPlan }) => (
  <>
    <div
      css={{
        ...fonts.serif,
        fontSize: "2rem",
        fontWeight: "bold",
        marginBottom: "2rem",
      }}
    >
      Choisissez votre abonnement
    </div>
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "2rem",
        paddingBottom: "-2rem",
      }}
    >
      {plans.map((p, index) => {
        return (
          <PlanChoiceCard
            css={{ marginBottom: "2rem" }}
            description={p.description}
            key={p.nickname}
            onClick={() => setSelectedPlan(p)}
            selected={selectedPlan._id === p._id}
            title={p.displayName}
          />
        );
      })}
    </div>
    <button
      // TODO: Loading state
      type="button"
      onClick={next}
      css={{
        ...buttonStyle,
        ":hover": {
          backgroundColor: Color("#AB47BC").darken(0.1).string(),
        },
        backgroundColor: "#AB47BC",
        color: "#FFF",
        fontSize: "1.2rem",
        fontWeight: "bold",
        padding: "0.8rem 1rem",
      }}
    >
      Continuer
    </button>
  </>
);

const PriceSelection = ({ selectedPlan }) => {
  const [selected, setSelected] = useState();

  const { data: pricesData } = API.get(
    () => `/stripe/products/${selectedPlan.iap.stripe}/prices`
  );
  const plan = { ...selectedPlan, stripePrices: pricesData?.prices };

  useEffect(() => {
    setSelected(
      pricesData?.prices.find((p) => p.metadata.promoted === "true").nickname
    );
  }, [pricesData]);

  const redirectToStripe = async () => {
    const {
      data: { sessionId },
    } = await API.do.post("/stripe/generate-checkout-session", {
      priceId: plan.stripePrices.find((p) => p.nickname === selected).id,
    });

    const stripe = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );

    await stripe.redirectToCheckout({ sessionId });
  };

  return !plan?.stripePrices ? (
    <Loading css={{ color: "#AB47BC" }} />
  ) : (
    <>
      <div
        css={{
          ...fonts.serif,
          fontSize: "2rem",
          fontWeight: "bold",
          marginBottom: "2rem",
        }}
      >
        {`S'abonner à Gaston ${plan.displayName}`}
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          paddingBottom: "-2rem",
        }}
      >
        {plan.stripePrices
          .sort((a) => (a.metadata.promoted ? -1 : 1))
          .map((p) => {
            const forceMonthly =
              p.recurring.interval === "year" &&
              p.recurring.interval_count === 1;
            return (
              <PriceChoiceCard
                advantage={p.metadata.advantage}
                css={{ marginBottom: "2rem" }}
                header={p.metadata.nickname}
                key={p.nickname}
                onClick={() => setSelected(p.nickname)}
                promoted={p.metadata.promoted === "true"}
                selected={selected === p.nickname}
                title={formatPrice(
                  forceMonthly ? p.unit_amount / 12 : p.unit_amount,
                  p.currency,
                  forceMonthly ? "month" : p.recurring.interval,
                  p.recurring.interval_count
                )}
              />
            );
          })}
      </div>
      <button
        // TODO: Loading state
        type="button"
        onClick={redirectToStripe}
        css={{
          ...buttonStyle,
          ":hover": {
            backgroundColor: Color("#AB47BC").darken(0.1).string(),
          },
          backgroundColor: "#AB47BC",
          color: "#FFF",
          fontSize: "1.2rem",
          fontWeight: "bold",
          padding: "0.8rem 1rem",
        }}
      >
        Passer au paiement
      </button>
    </>
  );
};

const SubscriptionModal = ({ isOpen, setIsOpen }) => {
  const { data: userData } = API.get("/user");
  const { data: plansData } = API.get("/plans");

  const [step, setStep] = useState("choosePlan");
  const [stripePlans, setStripePlans] = useState();
  const [selectedPlan, setSelectedPlan] = useState();

  useEffect(() => {
    const filtered = plansData?.plans.filter((p) => !p.hidden && p.iap?.stripe);
    const plan = filtered?.find?.((p) => p._id === userData?.user.planId);

    setStripePlans(filtered);
    setStep(plan ? "choosePrice" : "choosePlan");
    setSelectedPlan(plan || filtered?.[0]);
  }, [plansData, userData]);

  const next = () => {
    switch (step) {
      case "choosePlan":
        setStep("choosePrice");
        break;
      default:
        break;
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  if (isOpen) {
    return (
      <ClassNames>
        {({ css }) => (
          <Modal
            isOpen={isOpen}
            overlayClassName={{
              afterOpen: css({ opacity: "1 !important" }),
              base: css({
                ...fonts.sans,
                alignItems: "center",
                backgroundColor: "rgba(255,255,255,0.8)",
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                left: 0,
                opacity: 0,
                position: "fixed",
                right: 0,
                top: 0,
                transition: "opacity 120ms ease-in-out 0s",
              }),
              beforeClose: css({ opacity: "0 !important" }),
            }}
            onRequestClose={closeModal}
            className={css({
              backgroundColor: "#FFF",
              borderRadius: "0.4rem",
              boxShadow: "0 0 1rem 0 rgba(0,0,0,0.2)",
              display: "flex",
              flexDirection: "column",
              maxHeight: "100%",
              maxWidth: "360px",
              outline: "none",
              overflow: "scroll",
              padding: "2rem",
            })}
          >
            {!(userData?.user || plansData?.plans) && (
              <Loading css={{ color: "#AB47BC" }} />
            )}
            {step === "choosePlan" && (
              <PlanSelection
                plans={stripePlans}
                next={next}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
              />
            )}
            {step === "choosePrice" && (
              <PriceSelection selectedPlan={selectedPlan} />
            )}
          </Modal>
        )}
      </ClassNames>
    );
  }

  return <></>;
};

export default SubscriptionModal;
